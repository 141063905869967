import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { appNotificationActions } from '.';
import { getNotificationCommonProps } from './helpers';
import { NotificationsState, NotificationActions } from './types';

const initialNotificationsState: NotificationsState = {
  notifications: []
};

const notificationReducer: Reducer<NotificationsState, NotificationActions> = (
  state = initialNotificationsState,
  action
) => {
  switch (action.type) {
    case getType(appNotificationActions.cleanNotifications): {
      return initialNotificationsState;
    }
    case getType(appNotificationActions.notify): {
      const commonProps = getNotificationCommonProps(action.payload.type);

      const newNotification = {
        ...commonProps,
        ...action.payload
      };

      return {
        ...state,
        notifications: [...state.notifications, newNotification]
      };
    }
    case getType(appNotificationActions.updateSideNotification): {
      const notification = action.payload;
      const { id } = notification;

      return {
        ...state,
        notifications: state.notifications.map(item => (item.id === id ? { ...item, ...notification } : item))
      };
    }
    case getType(appNotificationActions.removeSideNotification): {
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.payload)
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;

import { User } from 'user';

import waitFor from '../waitFor';
export * as events from './events'

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

let intercomId: string;
export let isInit = false;

export const init = (id: string) => (intercomId = id);

export const load = () => {
  /* tslint:disable */
  (function() {
    let w: any = window;
    let ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', window.intercomSettings);
    } else {
      let d = document;
      let i: any = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args: any[]) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        let s = d.createElement('script');
        s.type = 'text/javascript';
        s.defer = true;
        s.src = 'https://widget.intercom.io/widget';

        setTimeout(() => document.body.appendChild(s), 1500);
      }
      if (document.readyState === 'complete') {
        l();
        return;
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const intercom = async (...a: any[]) => {
  await waitFor(() => window.Intercom, 10, 'wait for intercom loaded');
  window.Intercom && window.Intercom(...a);
};

type BootData = {
  hide_default_launcher: boolean
  name?: string,
  email?: string,
  avatar?: string,
  user_id?: string,
}

const boot = (data = {} as BootData) => {
  if (!intercomId) throw new Error('Intercom: no id provided');

  const { hide_default_launcher, ...userData } = data;

  isInit = true;

  intercom('boot', {
    app_id: intercomId,
    user_data: userData,
    hide_default_launcher
  });
};

export const setUser = (user: User, hide_default_launcher = false) => {
  const { username, email, userId, avatar } = user;

  if (username && email && userId && avatar) {
    intercom('shutdown');

    boot({
      email,
      avatar,
      user_id: userId,
      name: username,
      hide_default_launcher
    });
  }
};

export const setAnonymous = (props: BootData) => {
  intercom('shutdown');
  boot(props);
};

export const openChat = () => {
  intercom('show');
};

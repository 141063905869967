import { UploadFile, UploadFolder } from '@anm/api/modules/uploads';
import { BreadcrumbItemProps } from '@anm/components/Breadcrumbs/BreadcrumbItem';
import { uploadsActions, UploadsActions } from '@anm/store/modules/uploads';
import { isArray } from 'lodash/fp';
import pick from 'lodash/fp/pick';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { initialUploadsState, DEFAULT_BREADCRUMBS, DEFAULT_FETCH_PARAMS } from '.';

const uploadsListReducer: Reducer<typeof initialUploadsState, UploadsActions> = (
  state = initialUploadsState,
  action
) => {
  switch (action.type) {
    case getType(uploadsActions.updateBreadcrumbs):
      if (!action.payload) {
        return {
          ...state,
          search: '',
          isSearchOpen: false,
          breadcrumbs: DEFAULT_BREADCRUMBS
        };
      } else if (Array.isArray(action.payload)) {
        return {
          ...state,
          breadcrumbs: [...DEFAULT_BREADCRUMBS, ...action.payload]
        };
      }

      let newBreadcrumbs = [...state.breadcrumbs];

      const index = newBreadcrumbs.findIndex(b => b.id === (action.payload as BreadcrumbItemProps).id);

      if (index !== -1) {
        newBreadcrumbs = newBreadcrumbs.slice(0, index + 1);
      } else {
        newBreadcrumbs.push(action.payload);
      }

      return {
        ...state,
        search: '',
        isSearchOpen: false,
        breadcrumbs: newBreadcrumbs
      };
    case getType(uploadsActions.fetchUploadsAsync.success):
      const pageSize = state.params?.pageSize || DEFAULT_FETCH_PARAMS.pageSize;
      const hasMoreUploads = action.payload.length >= pageSize;
      const newList = state.params?.page === 1 ? action.payload : [...(state.data || []), ...action.payload];

      return {
        ...state,
        hasMoreUploads,
        isPending: false,
        data: newList
      };
    case getType(uploadsActions.updateFolderPathAsync.success):
      const list1 = [...(state.data || [])];

      const newPath = state.updateFolderMeta.params?.newPath;

      const indexOfFolder = list1?.findIndex((f: UploadFolder) => f.folderId === action.payload.id);

      if (newPath) {
        list1[indexOfFolder] = {
          ...list1[indexOfFolder],
          folderPath: newPath
        };
      }

      return {
        ...state,
        data: list1,
        updateFolderMeta: {
          ...state.updateFolderMeta,
          isPending: false
        }
      };
    case getType(uploadsActions.updateMediaMetaAsync.success):
      const list2 = [...(state.data || [])];

      const mediaName = state.updateMediaMeta.params?.name;

      const indexOfMedia = list2?.findIndex((m: UploadFile) => m.uploadId === action.payload.id);

      if (mediaName) {
        (list2[indexOfMedia] as UploadFile).meta.name = mediaName;
      }

      return {
        ...state,
        data: list2,
        updateMediaMeta: {
          ...state.updateMediaMeta,
          isPending: false
        }
      };
    case getType(uploadsActions.createFolderAsync.success):
      const { id: folderId, path: folderPath, dataCount: filesCount, ...restFolder } = action.payload[0];

      const newFolder = {
        folderId,
        folderPath,
        filesCount,
        isNew: true,
        ...pick(['userId', 'dateCreated', 'dateUpdated'], restFolder)
      };
      const list3 = [newFolder, ...(isArray(state.data) ? state.data : [])];

      return {
        ...state,
        data: list3,
        createFolder: {
          ...state.createFolder,
          isPending: false
        }
      };
    case getType(uploadsActions.removeNewAttributeFromFolder):
      const list5 = [...(state.data || [])].slice(1);

      const firstFolder = { ...(isArray(state.data) ? state.data : [])[0] };

      if (firstFolder) {
        firstFolder.isNew = false;

        list5.unshift({ ...firstFolder });
      }

      return {
        ...state,
        isError: false,
        data: list5
      };
    case getType(uploadsActions.deleteFolderAsync.success):
      const list4 = [...(state.data || [])].filter((item: UploadFolder) => item.folderId !== action.payload.id);

      return {
        ...state,
        data: list4,
        deleteFolder: {
          ...state.deleteFolder,
          isPending: false
        }
      };
    case getType(uploadsActions.removeListItem):
      const list8 = [...(state.data || [])].filter((u: UploadFile) => u.uploadId !== action.payload);

      return {
        ...state,
        data: list8
      };

    default:
      return state;
  }
};

export default uploadsListReducer;

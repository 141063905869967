import React, { FC } from 'react';

import { default as ButtonWrapper } from './Button';
import Container from './Container';
import Link from './Link';
import { ButtonContainerProps, ButtonElementProps, ButtonLinkProps, ButtonProps } from './types';

export * from './types';

const isLink = ({ href }: ButtonProps) => !!href;
const isContainer = ({ buttonType }: ButtonProps) => buttonType === 'container';

const Button: FC<ButtonProps> = ({ children, buttonType = 'button', ...props }) => {
  const isReactNode = typeof children === 'object';

  const commonProps = {
    buttonType,
    ...props,
    ...(isReactNode ? { children } : { dangerouslySetInnerHTML: { __html: children as string } })
  };

  if (isLink(props)) {
    return <Link {...(commonProps as ButtonLinkProps)} />;
  }
  if (isContainer({ ...props, buttonType })) {
    return <Container {...(commonProps as ButtonContainerProps)} />;
  }

  return <ButtonWrapper {...(commonProps as ButtonElementProps)} />;
};

export default Button;

import getCancelSource from '@anm/api/helpers/getCancelSource';
import { UploadFile } from '@anm/api/modules/uploads';
import cutFileExtension from '@anm/helpers/cutFileExtension';
import {
  getMediaSrc,
  isUploadFileExist,
  uploadsActions,
  UploadedFileProps,
  UploadsActions,
  UploadItem
} from '@anm/store/modules/uploads';
import pick from 'lodash/fp/pick';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { setIsStorageFull } from '../actions';

import { initialUploadsState } from '.';

const newUploadReducer: Reducer<typeof initialUploadsState, UploadsActions> = (state = initialUploadsState, action) => {
  switch (action.type) {
    case getType(uploadsActions.createUpload):
      const newProps = action.payload.uploads
        .map(({ file, objectUrl }) => {
          return {
            file,
            createHost: action.payload.createHost,
            name: cutFileExtension(file?.name || ''),
            url: objectUrl,
            size: file?.size,
            uploadType: action.payload.uploadType,
            isUploadFile: typeof action.payload.isUploadFile === 'undefined' ? true : action.payload.isUploadFile,
            cancelSource: getCancelSource()
          };
        })
        .filter(Boolean) as UploadedFileProps[];

      const newFiles = [...state.uploading.files, ...newProps];

      return {
        ...state,
        uploading: {
          ...state.uploading,
          files: newFiles,
          progressListener: action.payload.progressListener,
          isPending: true
        }
      };
    case getType(uploadsActions.createUploadAsync.success):
      const upload = action.payload.data.upload;
      const blobPreview = state.uploading.files.find(f => f.name === upload.meta.name)?.url;

      const uploadMedia = {
        ...upload,
        filesCount: 0,
        preview: upload.uploadType === 'audio' ? '' : blobPreview,
        uploadId: upload.id
      } as UploadItem;
      const list6 = [...(state.data || [])];

      const fileIndex = list6.findIndex((f: UploadFile) => f.uploadId);
      const insertIndex = fileIndex === -1 ? 0 : fileIndex;
      list6.splice(insertIndex, 0, uploadMedia);

      const uploadingFileIndex = state.uploading.files.findIndex(u => u.name === upload.meta.name && !u.uploadId);
      const newUploadingFiles = [...state.uploading.files];

      if (newUploadingFiles[uploadingFileIndex]) {
        newUploadingFiles[uploadingFileIndex] = {
          ...newUploadingFiles[uploadingFileIndex],
          uploadId: upload.id,
          hostId: action.payload.hostId
        };
      }

      return {
        ...state,
        data: list6,
        uploading: {
          ...state.uploading,
          isPending: false,
          isError: false,
          error: null,
          files: newUploadingFiles,
          uploads: {
            ...state.uploading.uploads,
            [upload.id]: {
              ...state.uploading.uploads[upload.id],
              fileId: action.payload.data.files[0].file.id,
              multipartUploadId: action.payload.multipartUploadId
            }
          }
        }
      };

    case getType(uploadsActions.updateProgress):
      return {
        ...state,
        uploading: {
          ...state.uploading,
          uploads: {
            ...state.uploading.uploads,
            [action.payload.id]: {
              ...state.uploading.uploads[action.payload.id],
              uploadProgress: action.payload.progress,
              isCompleted: false
            }
          }
        }
      };

    case getType(uploadsActions.clearCancelToken):
      const uploadFiles1 = [...state.uploading.files].map(f =>
        f.uploadId === action.payload ? { ...f, cancelSource: undefined } : f
      );

      return {
        ...state,
        uploading: {
          ...state.uploading,
          files: uploadFiles1
        }
      };

    case getType(setIsStorageFull):
      return {
        ...state,
        isStorageFull: action.payload
      };

    case getType(uploadsActions.changeUploadStatus):
      const { uploadId, duration, preview } = action.payload;
      const list9 = [...(state.data || [])];

      const indexToChange = list9.findIndex((u: UploadFile) => u.uploadId === uploadId);

      list9[indexToChange] = {
        ...list9[indexToChange],
        status: 'stable',
        preview,
        meta: {
          ...(list9[indexToChange] as UploadFile).meta,
          duration
        }
      };

      const filteredFiles = state.uploading.files.filter(f => f.uploadId !== uploadId);

      return {
        ...state,
        uploading: {
          ...state.uploading,
          files: filteredFiles
        },
        data: list9
      };

    case getType(uploadsActions.clearUploadedFiles): {
      return {
        ...state,
        uploading: {
          ...state.uploading,
          isPending: false,
          files: [],
          uploads: {}
        }
      };
    }

    case getType(uploadsActions.completeMultipartUploadAsync.success): {
      const fileId = action.payload.id;
      const updatedUploadId = Object.keys(state.uploading.uploads).find(
        u => state.uploading.uploads[u].fileId === fileId
      );

      if (!updatedUploadId) return state;

      return {
        ...state,
        uploading: {
          ...state.uploading,
          uploads: {
            ...state.uploading.uploads,
            [updatedUploadId]: {
              ...state.uploading.uploads[updatedUploadId],
              isCompleted: true
            }
          }
        }
      };
    }

    case getType(uploadsActions.createUploadAsync.failure):
      return {
        ...state,
        uploading: {
          ...state.uploading,
          ...initialUploadsState.uploading,
          isError: true,
          error: action.payload
        }
      };

    case getType(uploadsActions.fetchUploadMediaAsync.success): {
      const list7 = [...(state.data || [])];
      const { upload: uploadPayload } = action.payload;

      const isExistUploadFile = isUploadFileExist(action.payload);

      const media = list7.find((u: UploadFile) => u.uploadId === uploadPayload.id);

      const uploadFiles = [...state.uploading.files];

      const indexToUpdate = list7.findIndex((u: UploadFile) => u.uploadId === uploadPayload.id);

      const k = list7[indexToUpdate] as UploadFile;

      const isUpload = !k.preview || k.preview?.includes('blob');

      if (media && isExistUploadFile && isUpload) {
        const fileType = action.payload.upload.uploadType;
        const previewSrc = getMediaSrc(action.payload, fileType === 'audio' ? 'audio' : 'image');

        const uploadFile = {
          filesCount: 0,
          preview: previewSrc,
          uploadId: uploadPayload.id,
          ...pick(['dateCreated', 'dateUpdated', 'status', 'userId', 'uploadType', 'meta'], uploadPayload)
        };

        list7.splice(indexToUpdate, 1, uploadFile);

        const fileIndex = uploadFiles.findIndex(f => f.uploadId === uploadFile.uploadId);
        uploadFiles.splice(fileIndex, 1);
      }

      return {
        ...state,
        data: list7,
        fetchMediaUpload: {
          ...state.fetchMediaUpload,
          data: action.payload,
          isPending: false
        },
        uploading: {
          ...state.uploading,
          files: uploadFiles
        }
      };
    }

    default:
      return state;
  }
};

export default newUploadReducer;

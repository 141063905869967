import * as intercom from '@anm/helpers/intercom';
import isInIframe from '@anm/helpers/is/isInIframe';
import * as semrush from '@anm/helpers/semrush';
import { useEffect } from 'react';
import { User } from 'user';

const getCanLoadIntercom = () => !isInIframe() && !semrush.isSemrushIframe();

const updateIntercom = (profile: User | null, hide_default_launcher = false) => {
  const { setUser, isInit, setAnonymous } = intercom;

  if (profile) setUser(profile, hide_default_launcher);
  if (!profile && !isInit) setAnonymous({ hide_default_launcher });
};

type UseIntercomProps = {
  id: string;
  user: User | null;
  enable: boolean;
  hide_default_launcher?: boolean;
};

const useIntercom = ({ id, user, enable, hide_default_launcher }: UseIntercomProps) => {
  const canLoadIntercom = enable && getCanLoadIntercom();

  useEffect(() => {
    if (!canLoadIntercom) return;

    intercom.load();
    intercom.init(id);
  }, [canLoadIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) return;

    updateIntercom(user, hide_default_launcher);
  }, [canLoadIntercom, user?.userId]);
};

export default useIntercom;

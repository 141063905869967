import { UploadsActions } from '@anm/store/modules/uploads';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { changeSearchOpen, updateSearchTerm } from '../actions';

import { initialUploadsState } from '.';

const searchUploadsReducer: Reducer<typeof initialUploadsState, UploadsActions> = (
  state = initialUploadsState,
  action
) => {
  switch (action.type) {
    case getType(changeSearchOpen): {
      return {
        ...state,
        isSearchOpen: action.payload
      };
    }
    case getType(updateSearchTerm): {
      return {
        ...state,
        search: action.payload
      };
    }
    default:
      return state;
  }
};

export default searchUploadsReducer;

import { NotificationType } from '@anm/components/NotificationBlock';
import uniqueId from 'lodash/fp/uniqueId';

export const getNotificationCommonProps = (type: NotificationType) => {
  const id = uniqueId(`notification-${type}-`);

  const timeout = type === 'success' ? 5000 : 10000;

  return { timeout, id };
};

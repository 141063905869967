import { UploadMedia, UploadMediaFile } from '@anm/api/modules/uploads';
import { MediaFile } from '@anm/api/modules/uploads/types';
import { TUploadMedia } from '@anm/components/uploads/UploadItem';
import getExtensionFromPath from '@anm/helpers/getExtensionFromPath';
import { Features } from '@anm/shared/features';
import { newId } from '@anm/shared/randomstring';
import { ChunkItem } from '..';

export const getMediaSrc = (mediaFile: TUploadMedia['mediaUpload'], fileType = 'video', isStream = false): string => {
  const mediaFiles = mediaFile?.files.filter(
    m =>
      m.file.fileType === fileType &&
      ((fileType === 'image' && ['jpg', 'jpeg', 'gif', 'png'].includes(m.file.extension)) ||
        (fileType === 'video' && (isStream ? m.file.extension === 'm3u8' : m.file.extension === 'mp4')) ||
        (fileType === 'audio' && ['mp3', 'wav', 'ogg'].includes(m.file.extension)))
  );

  if (!mediaFiles || !mediaFiles[0]) return '';

  const isVerticalVideo = mediaFiles[0].file.info.width < mediaFiles[0].file.info.height;

  const sortedVideos = mediaFiles?.sort((a: UploadMedia, b: UploadMedia) => {
    const key = isVerticalVideo ? 'height' : 'width';

    return a.file.info[key] - b.file.info[key];
  });

  return sortedVideos[0].request.url;
};

const VIDEO_EXTENSIONS_PLAYABLE_IN_BROWSER = '.mp4;.m3u8';
const CODECS_PLAYABLE_IN_BROWSER = 'h264;vp8';

const AUDIO_EXTENSIONS_PLAYABLE_IN_BROWSER = '.mp3;.ogg;.wav';
const IMAGE_EXTENSION_SUPPORTED_BY_BROWSER = '.png;.jpeg;.jpg;.gif';

const isPlayableVideoFile = (file: MediaFile) =>
  VIDEO_EXTENSIONS_PLAYABLE_IN_BROWSER.includes(file.extension) && CODECS_PLAYABLE_IN_BROWSER.includes(file.info.codec);

const isPlayableAudioFile = (file: MediaFile) => AUDIO_EXTENSIONS_PLAYABLE_IN_BROWSER.includes(file.extension);

const canRenderImageFile = (file: MediaFile) => IMAGE_EXTENSION_SUPPORTED_BY_BROWSER.includes(file.extension);

export const isUploadFileExist = (payload: UploadMediaFile) => {
  const { upload: uploadPayload, files } = payload;
  const uploadType = uploadPayload.uploadType;

  const isExistUploadFile = files.some(
    f =>
      f.file.fileType === uploadType &&
      ((uploadType === 'video' && isPlayableVideoFile(f.file)) ||
        (uploadType === 'image' && canRenderImageFile(f.file)) ||
        (uploadType === 'audio' && isPlayableAudioFile(f.file)))
  );

  return isExistUploadFile;
};

const DEFAULT_EXTENSION = 'mp4';

export const getExtension = (file?: File) => getExtensionFromPath(file?.name || '') || DEFAULT_EXTENSION;

export const splitFile = (file: File) => {
  const chunkSize = Features.multipartUpload.chunkSizeMB * 1024 * 1024;
  const chunks = [] as ChunkItem[];

  for (let start = 0; start < file.size; start += chunkSize) {
    const chunk = file.slice(start, start + chunkSize);
    chunks.push({
      id: newId(),
      blob: chunk
    });
  }

  return chunks;
};

import { getSrc } from '@anm/helpers/image';
import Image, { ImageProps } from 'next/image';
import { FC } from 'react';

type NextImageProps = ImageProps & {
  itemProp?: string;
};

const NextImage: FC<NextImageProps> = ({ src, ...otherProps }) => <Image src={getSrc(src)} {...otherProps} />;

export default NextImage;

import { EmptyAction } from 'typesafe-actions';

type Handler<S extends {}> = {
  [key in string]: (state: S, action: unknown) => S;
};
const reduceFactory = <S extends {}, A extends EmptyAction<string>>(initialState: S, handlers: Handler<S>) => (
  state = initialState,
  action: A
) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reduceFactory;
